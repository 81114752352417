import axios, { AxiosResponse } from 'axios'
import { createContext, useCallback, useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { signIn, useSession } from 'next-auth/react'

import { baseURL } from 'configs/api'
import { UNPROTECTED_ROUTES } from 'configs/routes'
import { useGlobalContext } from 'pages/_app'
import Layout from 'components/Layout'
import { ErrorState } from 'components/ErrorState'
import { LoadingPageState } from 'components/LoadingState'
import { usePermission } from 'utils/hooks/usePermission'
import { getTokenData, persistTokenData } from 'utils/auth'
import { useAuthErrorStore } from 'stores/authError'
import { useUserStore } from 'stores/user'
import roles from 'configs/roles'
import menus from 'configs/menus'

import type { UserSession } from 'types/users'
import type { AuthError } from 'types/authError'

interface AuthContextTypeInterface {
  hasSession: Boolean
  hasUser: Boolean
}

interface AuthGuardInterface extends AuthContextTypeInterface {
  unsetSession: () => void
}

const AuthContextDefaultValue: AuthContextTypeInterface = {
  hasSession: false,
  hasUser: false,
}

const AuthContext = createContext<AuthContextTypeInterface>(
  AuthContextDefaultValue
)
AuthContext.displayName = 'AuthContext'

/**
  Functions
  */

function isUnprotectedRoutes(router) {
  return UNPROTECTED_ROUTES.includes(router.pathname)
}

function isRegistrationRoutes(router) {
  const basePath = router.pathname.split('/')[1]
  return basePath === 'register'
}

export function useAuthGuard(): AuthGuardInterface {
  const router = useRouter()
  const { data: session, status } = useSession()
  const { user, setUser } = useUserStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }))

  const { setAuthError } = useAuthErrorStore()

  const hasSession: Boolean =
    !!session?.user && Object.keys(session?.user).length > 0
  const hasUser: Boolean = !!user && Object.keys(user).length > 0

  const destroySession = useCallback(() => {
    router.push('/logout')
  }, [router])

  const invalidateGuruToken = useCallback(async () => {
    const guruToken =
      getTokenData()?.guruToken || session?.user?.initialGuruToken
    const path = '/teachers/v1alpha2/logout'
    if (hasUser) {
      try {
        const { data } = (await axios({
          url: `${baseURL}${path}`,
          method: 'POST',
          data: {
            grant_type: session.provider,
            token: session.id_token,
          },
          headers: {
            Authorization: `Bearer ${guruToken}`,
          },
        })) as AxiosResponse<UserSession>

        return data
      } catch (error) {
        return Promise.resolve()
      }
    } else {
      return null
    }
  }, [hasUser, session])

  const setSession = useCallback(async () => {
    try {
      if (!getTokenData()?.guruToken) {
        persistTokenData({
          userId: session?.user?.user?.id,
          email: session?.user?.user?.email,
          guruToken: session?.user?.initialGuruToken,
          expiredAt: session?.user?.expiredAt,
          idToken: session?.idToken,
        })
      }
      setUser(session?.user)
    } catch (error) {}
  }, [session, setUser])

  const unsetSession = useCallback(async () => {
    try {
      if (!isRegistrationRoutes(router)) {
        await invalidateGuruToken()
      }
      destroySession()
    } catch (error) {}
  }, [destroySession, invalidateGuruToken, router])

  const validateSession = useCallback(() => {
    try {
      if (hasUser) {
        return
      }

      if (session?.user?.error) {
        throw new Error(session.user.error)
      }

      !hasUser && setSession()
    } catch ({ message }) {
      const email = session?.user?.user?.email || session?.user?.email

      if (message === 'FetchGuruTokenErrorNetwork') {
        setAuthError({
          type: 'NetworkError',
        } as AuthError)
      } else if (
        email &&
        ['FetchGuruTokenErrorNotRegistered'].includes(message)
      ) {
        setAuthError({
          provider: (session?.provider || 'google').toLowerCase(),
          type: 'NotRegistered',
        } as AuthError)
      }

      destroySession()
    }
  }, [destroySession, hasUser, session, setAuthError, setSession])

  useEffect(() => {
    if (status === 'loading' || isUnprotectedRoutes(router)) {
      return // Do nothing while loading
    }

    if (hasSession) {
      validateSession()
      return
    }

    if (isRegistrationRoutes(router)) {
      router.push('/register')
      return
    }

    signIn() // If not authenticated, force log in
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession, router, status, validateSession])

  return {
    hasSession,
    hasUser,
    unsetSession,
  }
}

export function AuthGuard(props) {
  const { data: session } = useSession()
  const router = useRouter()
  const { getUserGroupNames } = useUserStore((state) => ({
    getUserGroupNames: state.getUserGroupNames,
  }))
  const { userData } = useGlobalContext()
  const { children = [] } = props
  const { hasSession, hasUser } = useAuthGuard()
  const { checkPermissions, currentPathPermissions } = usePermission()

  let permitted = checkPermissions(currentPathPermissions())
  const toolkitRoles = [roles.TOOLKIT_CONTRIBUTOR, roles.TOOLKIT_CURATOR]
  const otherMenuRoles: string[] = menus?.reduce((acc, menu) => {
    const itemsPermission = menu?.items?.reduce(
      (ac, item) => [...ac, ...item?.permissions],
      []
    )
    return [...acc, ...itemsPermission]
  }, [])
  const userSession = session?.user
  const isUserStatusPrecreate = userSession?.status === 'PrecreateRegisterDraft'
  const userGroups = userSession?.user?.groups?.map((group) => group?.name)
  const isUserToolkitAdmin = userGroups?.includes(roles.TOOLKIT_ADMIN)
  const userGroupsWithoutToolkitRoles = userGroups?.filter(
    (group) => !toolkitRoles.includes(group as any)
  )
  const isUserHaveOtherMenuRoles = userGroupsWithoutToolkitRoles?.some((role) =>
    otherMenuRoles?.includes(role)
  )

  const toolkitRoutes = [
    '/modul-ajar',
    '/modul-project',
    '/bahan-ajar',
    '/contributors',
    '/home',
  ]

  const isOnToolkitRoute = toolkitRoutes.some((route) =>
    router?.route?.includes?.(route)
  )
  const isOnMenuRoute = router?.route === '/menu'

  if (isUnprotectedRoutes(router)) {
    return children
  }

  const handleRedirectUserToRegister = () => {
    if (['Rejected', 'Draft'].includes(userData?.registration_state)) {
      router.replace('/register/form')
      return <LoadingPageState />
    }
    if (userData?.registration_state === 'Submitted') {
      router.replace('/register/pending-verification')
      return <LoadingPageState />
    }
  }

  if (hasSession && hasUser) {
    if (userSession != null && userGroups.length == 0) {
      permitted = false
    }
    if (
      userData &&
      ((isOnToolkitRoute && !isUserToolkitAdmin) ||
        (isOnMenuRoute && (isUserStatusPrecreate || !isUserHaveOtherMenuRoles)))
    ) {
      handleRedirectUserToRegister()
    }
    if (permitted) {
      return children
    }

    return (
      <Layout
        sidebar={() => null}
        hideSidebar
        bgClassName="bg-default"
        className="flex items-center justify-center"
      >
        <Head>
          <title>
            Ruang Kolaborasi Merdeka Mengajar - Akses tidak diijinkan
          </title>
        </Head>
        <ErrorState
          icon="report_problem"
          title="Anda tidak bisa mengakses halaman ini"
          description={`Maaf, ${
            userGroups.length > 0
              ? `sebagai <strong>${getUserGroupNames()}</strong>, `
              : ``
          } Anda tidak
                memiliki akses ke halaman ini. Silakan kembali ke halaman utama
                untuk mengakses menu lainnya.`}
          actionLink="/"
          actionText="Kembali ke Halaman Utama"
        />
      </Layout>
    )
  }

  // Session is being fetched, or no user.
  return <LoadingPageState />
}
