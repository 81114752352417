import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'

import { Button } from '@wartek-id/button'
import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import styles from './ErrorState.module.css'

interface ErrorStateProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
  icon?: string
  title?: string
  description?: string
  actionLink?: string
  actionText?: string
}

export function ErrorState({
  children,
  className,
  icon,
  title,
  description,
  actionLink,
  actionText,
  ...props
}: ErrorStateProps) {
  const containerClasses = clsx('flex flex-col justify-center', className)

  return (
    <div {...props} className={containerClasses}>
      {!!icon && (
        <div className="flex justify-center mb-8">
          <Icon as="i" color="default" fontSize="large">
            {icon}
          </Icon>
        </div>
      )}

      {children && <div>{children}</div>}

      {!children && (
        <div className="text-center">
          <Text className="mb-2" color="default" variant="display-sm">
            {title}
          </Text>

          <Text
            className={styles.ErrorStateDescription}
            color="default"
            variant="body"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          {!!actionLink && !!actionText && (
            <div className="flex justify-center">
              <Link href={actionLink} passHref>
                <Button as="a" variant="solid" color="blue" className="mt-4">
                  {actionText}
                </Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
